<template>
    <div v-if="!loadingPage">
        <select-shop :loadingButton="!loadingButton"></select-shop>
        <div v-if="isPermission">
            <CCard v-if="!loading" class="shadow-sm">
                <CCardBody v-if="promotions.length > 0" class="p-4">
                    <div class="promotion-box-header row">
                        <div class="col-md-9 col-sm-12">
                            <h2>
                                <span>{{ $t('promotion') }}</span>
                            </h2>
                            <show-date></show-date>
                            <!-- <p class="mt-2">{{ $t("moreInfo") }} <i class="fi fi-rr-info btn p-0 "
                                    @click="openHelpModal = true"></i></p> -->
                        </div>
                        <div class="col-md-3 col-sm-12 text-right">
                            <CButton to="/promotion/template" block color="success"
                                style="display: flex; align-items: center; justify-content: center;color: white;">
                                <i class="fi fi-rr-plus mr-1"></i>{{ $t("createPromotion") }}
                            </CButton>
                        </div>
                        <div class="col-12 mt-2 d-flex justify-content-between">
                            <select v-model="selectedType" style="width: 100%;" class="custom-select">
                                <option :value="null">{{ $t("viewAllFood") }}</option>
                                <option v-for="option in discountOptions" :key="option.value" :value="option.value">
                                    {{ $t(option.label) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="promotion-container mt-2 row">
                        <div v-for="(promotion, index) in sortedPromotions" :key="promotion.objectId"
                            class="col-sm-12 col-md-4 p-2">
                            <div @click="showDetail(promotion.objectId)" class="promotion-card p-0 position-relative">
                                <div class="icon-top-left-container position-absolute">
                                    <div class="d-flex align-items-center">
                                        <CBadge v-if="promotion.isActive && !isExpired(promotion)" class="pl-2 pr-2"
                                            style="background-color: white; border-radius: 10px">
                                            <i style="color: var(--success);" class="fi fi-rr-dot-circle"></i>
                                            {{ $t('promotionActive') }}
                                        </CBadge>
                                        <CBadge v-else-if="!promotion.isActive && !isExpired(promotion)"
                                            class="pl-2 pr-2" style="background-color: white; border-radius: 10px">
                                            <i style="color: var(--danger);" class="fi fi-rr-dot-circle"></i>
                                            {{ $t('promotionNotActive') }}
                                        </CBadge>
                                        <CBadge v-if="isExpired(promotion)" class="pl-2 pr-2"
                                            style="background-color: red;color: white; border-radius: 10px">
                                            <i class="fi fi-rr-calendar-xmark"></i> {{ $t('promotionExpired') }}
                                        </CBadge>
                                        <CBadge v-if="isGrabPromotion(promotion)" class="pl-2 pr-2 ml-2"
                                            style="background-color: white; border-radius: 10px">
                                            <img width="54px" src="/img/grabFood.svg">
                                        </CBadge>
                                    </div>

                                    <div v-if="promotion.usageCount && promotion.usageCount > 0">
                                        <CBadge class="pl-2 pr-2"
                                            style="background-color:  var(--success);color: white; border-radius: 10px">
                                            <i class="fi fi-rr-receipt"></i> {{ $t('usedPromotionBadge') }} {{
                                                promotion.usageCount }} {{ $t('usedPromotionBadgeDesp') }}
                                        </CBadge>
                                    </div>
                                </div>
                                <img style="aspect-ratio: 16/9;object-fit:cover" width="100%;"
                                    :src="promotion.details.remoteImagePath || 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'">
                                <div class="text-left p-2">
                                    <h5 class="limited-text-one">{{ promotion.details.title }}</h5>
                                    <div style="color: gray;">
                                        <div class="d-flex align-items-center mb-2">
                                            <i class="fi fi-rr-badge-percent mr-2 mb-0"></i>
                                            {{ $t('promotion') }} : {{ $t(promotion.type) }}
                                        </div>
                                        <div class="d-flex align-items-center mb-2">
                                            <i class="fi fi-rr-comment-question mr-2 mb-0"></i>
                                            {{ $t('conditionHeader') }} : {{ $t(promotion.condition.type) }}
                                        </div>
                                        <div class="d-flex align-items-center mb-2">
                                            <i class="fi fi-rr-calendar-clock mr-2 mb-0"></i>
                                            {{ parseISODate(promotion.startDate) }}
                                            {{ $t('to') }}
                                            {{ parseISODate(promotion.endDate) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </CCardBody>
                <CCardBody v-else>
                    <div class=" w-100 row ">
                        <div class="row w-100 p-4">
                            <div class="col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                                <div class="text-center w-100">
                                    <h3 class="mb-3">{{ $t('promotionHeaderEmpty') }} <CBadge color="success">new
                                        </CBadge>
                                    </h3>
                                    <div style="">
                                        <!-- <span style="line-height: 220%; color: gray">
                                            {{ $t('promotionHeaderEmptyDesp') }}
                                        </span><br> -->
                                        <CButton color="dark" to="/promotion/template" class="mt-3 pr-3 pl-3">
                                            {{
                                                $t('createPromotion')
                                            }}
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 pl-4 pr-4">
                            <div v-for="(feature, index) in keyFeatures" class="col-md-4 col-sm-12 p-2">
                                <div class="feature-box">
                                    <div class="p-4 text-left">
                                        <h1 v-if="index !== 0"><i :class="feature.icon"></i></h1>
                                        <h1 v-else> <img width="140px" src="/img/grabFoodWhite.svg"></h1>
                                        <span class="text-left" style="font-size: 16px;">
                                            {{ $t(`promotionFeatures[${index}].description` || '') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="w-100 text-center mt-2" style="color: gray;">* {{ $t('promotionHeaderEmptyDesp')
                            }}</span>
                    </div>
                </CCardBody>
            </CCard>
            <CCard v-if="loading" class="shadow-sm">
                <CCardBody class="p-5">
                    <div style="width: 100%;height: 100vh;display: flex;justify-content: center;">
                        <CSpinner color="success"></CSpinner>
                    </div>
                </CCardBody>
            </CCard>
            <CModal :show.sync="openHelpModal" centered :title="$t('userManual')" color="success" header="false"
                size="lg">
                <template #footer>
                    <CRow class="justify-content-around col-md-12">
                        <CCol col="4">
                            <CButton @click="openHelpModal = false" color="outline-success" block>
                                <b>{{ $t("submit") }}</b>
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>
        </div>
        <div v-else>
            <!-- <access-data></access-data> -->
            <CCard class="shadow-sm ">
                <CCardBody>
                    <div class="">
                        <div class="row w-100 p-4 text-center">
                            <div class="col-md-12 col-sm-12 ">
                                <div class="text-center w-100">
                                    <h3 class="mb-3">{{ $t('promotionHeaderEmpty') }} <CBadge color="success">new
                                        </CBadge>
                                    </h3>
                                    <div>
                                        <span style="line-height: 220%; color: gray">
                                            {{ $t('forSmartPlusPackage') }}
                                        </span><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 pl-4 pr-4">
                            <div v-for="(feature, index) in keyFeatures" class="col-md-4 col-sm-12 p-2">
                                <div class="feature-box">
                                    <div class="p-4 text-left">
                                        <h1 v-if="index !== 0"><i :class="feature.icon"></i></h1>
                                        <h1 v-else> <img width="140px" src="/img/grabFoodWhite.svg"></h1>
                                        <span class="text-left" style="font-size: 16px;">
                                            {{ $t(`promotionFeatures[${index}].description` || '') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="w-100 text-center mt-2" style="color: gray;">* {{ $t('promotionHeaderEmptyDesp')
                            }}</span>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    </div>
    <div v-else>
        <CCard>
            <CCardBody style="padding-top: 20cap;">
                <div style="width: 100%;height: 100vh;display: flex;justify-content: center;">
                    <CSpinner color="success"></CSpinner>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import pos from '@/services/local'
import moment from 'moment'
import '@/assets/styles/promotion.css'
import util from '@/util/util'


export default {
    components: {

    },
    data() {
        return {
            loadingButton: false,
            openHelpModal: false,
            promotions: [],
            selectedType: null,
            discountOptions: [
                { value: "ITEM_FIXED_PRICE", label: "ITEM_FIXED_PRICE" },
                { value: "CATEGORY_FIXED_PRICE", label: "CATEGORY_FIXED_PRICE" },
                { value: "ITEM_PERCENTAGE_DISCOUNT", label: "ITEM_PERCENTAGE_DISCOUNT" },
                { value: "ITEM_DOLLAR_DISCOUNT", label: "ITEM_DOLLAR_DISCOUNT" },
                { value: "CATEGORY_PERCENTAGE_DISCOUNT", label: "CATEGORY_PERCENTAGE_DISCOUNT" },
                { value: "CATEGORY_DOLLAR_DISCOUNT", label: "CATEGORY_DOLLAR_DISCOUNT" },
                { value: "RECEIPT_PERCENTAGE_DISCOUNT", label: "RECEIPT_PERCENTAGE_DISCOUNT" },
                { value: "RECEIPT_DOLLAR_DISCOUNT", label: "RECEIPT_DOLLAR_DISCOUNT" },
                { value: "ITEM_X_FREE_Y", label: "ITEM_X_FREE_Y" },
                { value: "CATEGORY_X_FREE_Y", label: "CATEGORY_X_FREE_Y" },
                { value: "FREE_ITEM", label: "FREE_ITEM" }
            ],
            keyFeatures: [
                { icon: 'fi fi-rr-magic-wand' },
                { icon: 'fi fi-rr-magic-wand' },
                { icon: 'fi fi-rr-magic-wand' },
                { icon: 'fi fi-rr-layers' },
                { icon: 'fi fi-rr-clock ' },
                { icon: 'fi fi-rr-tally  ' },
            ],
            loading: false,
            orderShopSetting: {},
            loadingPage: false,
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            const shopObjectId = localStorage.getItem('shopObjectId');
            const selectedShop = this.shops.find(shop => shop.objectId === shopObjectId) || null
            return selectedShop?.currentPlan == "multiplePOS" && this.orderShopSetting?.isGrabOrderEnabled == true
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
        },
        uid() {
            return localStorage.getItem('uid')
        },
        sortedPromotions() {
            const currentDate = new Date();

            return (this.selectedType === null
                ? this.promotions
                : this.promotions.filter(promotion => promotion.type === this.selectedType)
            ).sort((a, b) => {
                const aEndDate = new Date(a.endDate);
                const bEndDate = new Date(b.endDate);

                const aIsFuture = aEndDate < currentDate;
                const bIsFuture = bEndDate < currentDate;

                // Push promotions with endDate > currentDate to the end
                if (aIsFuture && !bIsFuture) return 1; // a goes after b
                if (!aIsFuture && bIsFuture) return -1; // a goes before b

                // If both are in the same range, sort by created_at
                return new Date(b.created_at) - new Date(a.created_at);
            });
        },
        isGrabOrderEnabled() {
            return this.orderShopSetting?.isGrabOrderEnabled || false
        }
    },
    created() {
        this.getPromotion()
        this.getOrderShopSetting()
    },
    methods: {
        getPromotion() {
            this.loading = true
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
            }
            pos
                .get(`/api/v3.0/shop/promotions/${this.shopObjectId}`, {
                    headers: headers
                })
                .then((res) => {
                    this.promotions = res.data?.data || []
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                });
        },
        parseISODate(isoDate) {
            const date = new Date(isoDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year}, ${hours}:${minutes}`;
        },

        showDetail(objectId) {
            this.$router.push(`/promotion/details/${objectId}`)
        },
        isExpired(promotion) {
            const currentDate = new Date();
            const endDate = new Date(promotion.endDate);
            return currentDate > endDate;
        },
        isGrabPromotion(promotion) {
            return promotion.scopes.includes('GRAB')
        },
        getOrderShopSetting() {
            this.loadingPage = true
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopSetting = res?.data?.data[0] || null
                this.loadingPage = false
            })
        },
        getSuggestPromotionTitle(type, results) {
            const title = util.generatePromotionResultTitle(type, results)
            return title
        },
        getSuggestPromotionDescription(condition) {
            const description = util.generatePromotionConditionTitle(condition)
            return description
        }
    },
    watch: {
        shopObjectId(newVal, oldVal) {
            this.getPromotion()
            this.getOrderShopSetting()
        },
    },
}
</script>
<style></style>